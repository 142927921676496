import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'


const MXTicketPage = () => (
    <Layout>
        <SEO title="MxTicket" keywords={[`Nesting`, `Production Environment`, `react`]} />
        <div className="BlueGradient">
        <div className="--headerClear"></div>
            <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt --heroAnimation  ">
                <h2><span>MxTicket</span> removes messy paperwork</h2>
                <p>Simplify part handling and increase your part flow with MxTicket.</p>
            </section>
        </div>
        <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>Product Overview</h5>
        <p className="DarkP">Paperwork and job packets are replaced with one easy-to-use process ticket that includes a picture of the part, making it easier for shop personnel to sort jobs. Tickets are automatically created when needed, ensuring that up-to-date information makes its way to the shop floor. Insert hot jobs or change production queues as needed and MxTicket will get the right information to the shop floor quickly. Without messy paperwork, shop personnel quickly locate, process and move parts through the laser department. </p>
      </article>
    </section>
    <div className="--featImg" id="mXTicketImg"></div>
    <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --alignCenter">
      <article>
        <h2 className="DarkH">Regain unattended production time</h2>
        <p className="DarkP">Tickets are automatically created when needed, ensuring that up-to-date information makes its way to the shop floor. Hot jobs and production changes can be initiated from the office without causing lost time and additional manpower on the shop floor. Faster and easier part processing means more productivity and more parts flowing downstream.</p>
      </article>
    </section>
    <section className="--sectionPace --centerTxt">
      <h2 className="DarkH">Expand MxTicket with additional modules</h2>
      <p className="DarkP">Customize and control your suite of Mx products based on your shop needs. </p>
      <div className="--headerClear"></div>
      <div className="MXproducts" id="MxProductPage">
        <Link to="/mx"><span><h4>MxManager</h4></span></Link>
          <Link to="/mxmrp"> 
            <div id="ProductChip">
              <h4>MxMRP</h4>
              <hr></hr>
              <p>Real-Time Monitoring</p>
            </div>
          </Link>
          <Link to="/mxpallet"> 
            <div id="ProductChip">
              <h4>MxPallet</h4>
              <hr></hr>
              <p>Expedite finished pallets</p>
            </div>
          </Link>
          <Link to="/mxticket"> 
            <div id="ProductChip">
              <h4>MxTicket</h4>
              <hr></hr>
              <p>Smarter Labels & Tickets</p>
            </div>
          </Link>
          <Link to="/mxstation"> 
            <div id="ProductChip">
              <h4>MxStation</h4>
              <hr></hr>
              <p>Process Queue Control</p>
            </div>
          </Link>
          <Link to="/mxreport"> 
            <div id="ProductChip">
              <h4>MxReport</h4>
              <hr></hr>
              <p>Pinpoint Documentation</p>
            </div>
          </Link>
        </div>
      </section>
      <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">

        <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
    </Layout>
)

export default MXTicketPage